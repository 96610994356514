import {AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {HupSubscriptionsService} from "../../../data/services/hup-subscriptions.service";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {TranslationService} from "../../../core/services/translation.service";
import {CookieService} from "ngx-cookie";
import {BaseData} from "../../../data/models/base-data.model";
import {BaseDataService} from "../../../core/services/base-data.service";
import {Offer} from "../../../data/models/offer.model";
import {OfferService} from "../../../data/services/offer.service";
import {NavigationCommand, NavigationService} from "../../../data/services/navigation.service";
import {RedirectService} from "../../../data/services/redirect.service";
import {ConfigService} from "../../../core/services/config.service";
import {interval, Subscription, timer} from 'rxjs';
import {LoginService} from "../../../core/components/login/login.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-offer-ordered-overview',
  templateUrl: './offer-ordered-overview.component.html',
  styleUrls: ['./offer-ordered-overview.component.scss']
})
export class OfferOrderedOverviewComponent implements OnInit, OnDestroy, AfterViewInit {

  order: any;
  offer: Offer;
  isUserLoggedIn: boolean = false;
  allFormsOfPayment: BaseData[] = [];
  allPaymentFrequencies: BaseData[] = [];
  redirectUrl: string;
  timeUntilRedirectSeconds: number = 0;

  timerSub: Subscription;


  constructor(
    private subscriptionService: HupSubscriptionsService,
    private route: ActivatedRoute,
    public navigationService: NavigationService,
    public translationService: TranslationService,
    private cookieService: CookieService,
    private baseDataService: BaseDataService,
    private offerService: OfferService,
    private redirectService: RedirectService,
    private configService: ConfigService,
    private loginService: LoginService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.route.paramMap.subscribe((params) => {
      this.allPaymentFrequencies = this.baseDataService.getBaseData('invoicemethods');
      this.allFormsOfPayment = this.baseDataService.getBaseData('paymentmethods');
      if( isPlatformBrowser(platformId)) {
        if (params.get('otlc') && params.get('otlc') !== '0') {
          const otlcCode = params.get('otlc');
          if (otlcCode.length) {
            this.loginService.loginByOtlc(otlcCode);
            // wir dürfen erst weitermachen, wenn er wirklich eingeloggt ist..
            this.loginService.getAuthData().subscribe(authData => {
              if (!!authData && (authData.token !== null)) {
                this.handleParams(params);
              }
            });
          }
        } else  {
          this.loginService.getAuthData().subscribe(authData => {
            if (!!authData && (authData.token !== null)) {
              this.handleParams(params);
            }
          });
        }
      }
    });
    this.route.queryParamMap.subscribe((queryParams) => {
      this.redirectUrl = queryParams.get("redirect_URL");
    });
    configService.loadConfig("order.timeUntilRedirect").subscribe(config => {
      if (this.redirectUrl?.length > 0 && Number(config?.value) > 0) {
        this.timeUntilRedirectSeconds = Number(config.value);

        const intervalSubscription = interval(1000).subscribe(() => {
            this.timeUntilRedirectSeconds = Math.max(0, --this.timeUntilRedirectSeconds);
            if (this.timeUntilRedirectSeconds == 0) {
              intervalSubscription.unsubscribe();
            }
          });

        const source = timer(this.timeUntilRedirectSeconds * 1000);
        this.timerSub = source.subscribe(() => {
          this.redirectService.redirect(this.redirectUrl);
        });
      }
    })
  }

  private handleParams(params: ParamMap) {
      this.isUserLoggedIn = true;
      this.subscriptionService.getSubscription(params.get('webId'), params.get('backendId')).subscribe(subscription => {
        this.order = subscription;
        let houseNoParts = this.order.curBilling?.address?.houseno.split('#');
        if ('01' === houseNoParts.shift()) {
          this.order.curBilling.address.houseno = houseNoParts[0];
          this.order.curBilling.address.stock = houseNoParts[1];
          this.order.curBilling.address.stiege = houseNoParts[2];
          this.order.curBilling.address.tuer = houseNoParts[3];
        } else {
          this.order.curBilling.address.houseno = houseNoParts[0];
        }
        houseNoParts = this.order.curDelivery?.address?.houseno.split('#');
        if ('01' === houseNoParts.shift()) {
          this.order.curDelivery.address.houseno = houseNoParts[0];
          this.order.curDelivery.address.stock = houseNoParts[1];
          this.order.curDelivery.address.stiege = houseNoParts[2];
          this.order.curDelivery.address.tuer = houseNoParts[3];
        } else {
          this.order.curDelivery.address.houseno = houseNoParts[0];
        }
        if (subscription.paymentList?.length > 0) {
          this.order.curPayment = subscription.paymentList[0];
          const paymentMethod = this.allFormsOfPayment.find(method => method.key === this.order.curPayment.paymentType.paymentMethod);
          this.order.curPayment.paymentType.payMentMethodDescription = paymentMethod?.description;
          const paymentFrequency = this.allPaymentFrequencies.find(frequency => frequency.key === this.order.curPayment.paymentType.paymentFrequency);
          this.order.curPayment.paymentType.paymentFrequencyDescription = paymentFrequency?.description;
          this.offerService.getOfferDetail(this.order?.offer?.orderChoiceKey).subscribe(offer => {
            this.offer = offer;
          });
        }

      });
  }

  ngOnInit(): void {
  }

  routeToOrderList(): void {
    this.navigationService.navigateTo(NavigationCommand.SUBSCRIPTION);
  }

  ngOnDestroy(): void {
    if (this.timerSub && !this.timerSub?.closed) {
      this.timerSub?.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
  }

}
